:root {
  --off-white: #fafafa;
}
.nav-items{
  margin-left: 0px;
  padding-left: 0px;
}
.nav {
  background-color: var(--off-white);
  position: fixed;
  top: 0px;
  width: 100%;
  height: 80px;
  z-index: 1000;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
}

.nav-logo {
  height: 60px;
  width: 60px;
}

.nav-logo:hover {
  cursor: pointer;
}

.nav .nav-content {
  max-width: 900px;
  padding: 0rem 5.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.nav-item {
  display: inline-block;
  margin-right: 2rem;
  color: #333;
}

.nav-item:hover {
  color: black;
  cursor: pointer;
}
a.active {
  color: rgb(66, 119, 148, 0.9);
}


h1, legend {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 48px;
  margin-top: 10px;
}

.section {
  max-width: 1200px;
  background: rgba(255, 255, 255, 0.9);
  color:rgb(70, 70, 70);
  display: block;
  clear: both;
}

.section-dark {
  background: rgba(66, 119, 148, 0.9);
  color: white;
}

.section-content {
  max-width: 1200px;
  padding: 40px;
}


.Content {
  position: relative;
  float: left;
  left: 3rem;
  top: 80px;
  max-width: 1600px;
  z-index: 1;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.BG {
  width: 80%;
  height: 100%;
  right: 0px;
  top: 200px;
  position: fixed;
  z-index: -1;
}
.featuredContainer {
  display: grid;
  grid-row: auto;
}
.featuredBox {
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.featuredBox img {
  margin-right: 20px;
  max-width: 530px;
  float: left;
}
.featuredBox a, .featuredBox a:visited {
  color: #ffffff;
}
.artworksContainer {
  margin-bottom: 40px;
  display: block;
  float: left;
}
#ReactGridGallery {
  clear: both;
  display: block;
}
.clearfix {
  clear: both;
}

.example-image-link img {
  margin-right: 4px;
}
input, textarea, button {
    padding: 5px;
    font-size: 10px;
}
#submitButton {
  width: 300px;
}
legend, fieldset {
    border: none;
}
fieldset {
    width: 70%;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}
.contactRow .middleBlock {
    padding: 0px;
}
.middleBlock form, .middleBlock form fieldset {
    margin: 0px;
    padding: 0px;
    width: 70%;
}
.middleBlock form, .middleBlock form fieldset {
    margin: 0px;
    padding: 0px;
    width: 70%;
}

#closeTab {
  position: fixed;
  top: 80px;
  z-index: 1000;
  cursor: pointer;
  padding: 5px;
  background-color: #ffffff;
  right: 10px;
  visibility: hidden;
}
.closeTabVisible {
  visibility: visible !important;
}

.bottomSpace {
  padding-bottom: 1500px;
}

@media screen and (max-width: 2000px) {
  .section-content, .section, .Content {
    max-width: 800px;
  }
  .featuredBox img {
    max-width: 360px;
  }
  input, textarea, button {
      padding: 5px;
      font-size: 10px;
  }
  legend, fieldset {
      border: none;
  }
  fieldset {
      width: 70%;
      margin-top: 20px !important;
      margin-bottom: 20px !important;
  }
  .contactRow .middleBlock {
      padding: 0px;
  }
  .middleBlock form, .middleBlock form fieldset {
      margin: 0px;
      padding: 0px;
      width: 70%;
  }
  .middleBlock form, .middleBlock form fieldset {
      margin: 0px;
      padding: 0px;
      width: 70%;
  }
}
.Loader {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-align: center;
}

@media screen and (max-width: 1400px) {
  .Content {
    width: 490px;
  }
  .BG {
    top: 80px;
  }
  .show-content {
    left: 0px !important;
  }
  .example-image-link img {
    max-width: 100px;
  }
  .nav, .nav-content {
    padding: 0rem 20px !important;
  }
  .featuredBox img {
      margin-bottom: 10px;
  }
  input, textarea, button {
      padding: 5px;
      font-size: 10px;
  }
  legend, fieldset {
      border: none;
  }
  fieldset {
      width: 70%;
      margin-top: 20px !important;
      margin-bottom: 20px !important;
  }
  .contactRow .middleBlock {
      padding: 0px;
  }
  .middleBlock form, .middleBlock form fieldset {
      margin: 0px;
      padding: 0px;
      width: 70%;
  }
  .middleBlock form, .middleBlock form fieldset {
      margin: 0px;
      padding: 0px;
      width: 70%;
  }
}





@media screen and (max-width: 800px) {
  .nav {
    width: 100%;
    padding-right: 150px;
  }
  .Content {
    left: -100%;
    width: 100%;
  }
  .BG {
    width: 100%;
  }
  .rowA {
      padding-bottom: 0px;
  }
  h1 {
      font-size: 30px;
      margin-top: 0px !important;
  }
  h2 {
      font-size: 15px;
  }
  p {
      font-size: 10px;
  }
  input, textarea, button {
      padding: 5px;
      font-size: 10px;
  }
  legend, fieldset {
      border: none;
  }
  fieldset {
      width: 70%;
      margin-top: 20px !important;
      margin-bottom: 20px !important;
  }
  .contactRow .middleBlock {
      padding: 0px;
  }
  .middleBlock form, .middleBlock form fieldset {
      margin: 0px;
      padding: 0px;
      width: 70%;
  }
  .react-player div {
      height: 200px !important;
  }
  .featuredBox img {
      margin-bottom: 10px;
  }
  #rc-imageselect, .g-recaptcha {transform:scale(0.67);-webkit-transform:scale(0.67);transform-origin:0 0;-webkit-transform-origin:0 0;}
}

@media screen and (max-width: 600px) {

  .featuredBox {
    width: 280px;
    margin-bottom: 20px;
  }
  .featuredBox img {
      max-width: 250px;
      margin-bottom: 10px;
  }
}